export const CONFIRM_MINT_NFT_DIALOG_SHOW = 'CONFIRM_MINT_NFT_DIALOG_SHOW';
export const CONFIRM_MINT_NFT_DIALOG_HIDE = 'CONFIRM_MINT_NFT_DIALOG_HIDE';

export const RECAP_VIDEO_GENERATE_IN_PROGRESS = 'RECAP_VIDEO_GENERATE_IN_PROGRESS';
export const RECAP_VIDEO_GENERATE_SUCCESS = 'RECAP_VIDEO_GENERATE_SUCCESS';
export const RECAP_VIDEO_GENERATE_ERROR = 'RECAP_VIDEO_GENERATE_ERROR';

export const RECAP_VIDEO_FETCH_IN_PROGRESS = 'RECAP_VIDEO_FETCH_IN_PROGRESS';
export const RECAP_VIDEO_FETCH_SUCCESS = 'RECAP_VIDEO_FETCH_SUCCESS';
export const RECAP_VIDEO_FETCH_ERROR = 'RECAP_VIDEO_FETCH_ERROR';

export const ROUNDUP_STATS_FETCH_IN_PROGRESS = 'ROUNDUP_STATS_FETCH_IN_PROGRESS';
export const ROUNDUP_STATS_FETCH_SUCCESS = 'ROUNDUP_STATS_FETCH_SUCCESS';
export const ROUNDUP_STATS_FETCH_ERROR = 'ROUNDUP_STATS_FETCH_ERROR';

export const GENERATE_VIDEO_IN_PROGRESS_SET = 'GENERATE_VIDEO_IN_PROGRESS_SET';
export const MINT_NFT_IN_PROGRESS_SET = 'MINT_NFT_IN_PROGRESS_SET';
export const GENERATE_VIDEO_STATUS_SET = 'GENERATE_VIDEO_STATUS_SET';

export const FETCH_NFT_DETAILS_SUCCESS = 'FETCH_NFT_DETAILS_SUCCESS';
export const FETCH_NFT_DETAILS_IN_PROGRESS = 'FETCH_NFT_DETAILS_IN_PROGRESS';
export const FETCH_NFT_DETAILS_ERROR = 'FETCH_NFT_DETAILS_ERROR';

export const MINT_NFT_SUCCESS = 'MINT_NFT_SUCCESS';
export const MINT_NFT_IN_PROGRESS = 'MINT_NFT_IN_PROGRESS';
export const MINT_NFT_ERROR = 'MINT_NFT_ERROR';

export const MINT_REQUEST_FETCH_IN_PROGRESS = 'MINT_REQUEST_FETCH_IN_PROGRESS';
export const MINT_REQUEST_FETCH_SUCCESS = 'MINT_REQUEST_FETCH_SUCCESS';
export const MINT_REQUEST_FETCH_ERROR = 'MINT_REQUEST_FETCH_ERROR';

export const MINT_QUEUE_FETCH_IN_PROGRESS = 'MINT_QUEUE_FETCH_IN_PROGRESS';
export const MINT_QUEUE_FETCH_SUCCESS = 'MINT_QUEUE_FETCH_SUCCESS';
export const MINT_QUEUE_FETCH_ERROR = 'MINT_QUEUE_FETCH_ERROR';

export const PROJECTS_LIST_FETCH_IN_PROGRESS = 'PROJECTS_LIST_FETCH_IN_PROGRESS';
export const PROJECTS_LIST_FETCH_SUCCESS = 'PROJECTS_LIST_FETCH_SUCCESS';
export const PROJECTS_LIST_FETCH_ERROR = 'PROJECTS_LIST_FETCH_ERROR';

export const NFT_CLAIM_STATUS_CHECK_IN_PROGRESS = 'NFT_CLAIM_STATUS_CHECK_IN_PROGRESS';
export const NFT_CLAIM_STATUS_CHECK_SUCCESS = 'NFT_CLAIM_STATUS_CHECK_SUCCESS';
export const NFT_CLAIM_STATUS_CHECK_ERROR = 'NFT_CLAIM_STATUS_CHECK_ERROR';
