import { ACTIVITY_URL, config } from '../config';

const URL = config.API_URL;
const REST_URL = config.REST_URL;
const DATA_LAYER = config.DATA_LAYER;
const LAUNCH_PAD = config.LAUNCH_PAD;
export const RECAP_URL = 'https://recap-dev-api.omniflix.studio';
export const NFT_CLAIM = 'https://dev-api-recap-2022.ctlabs.in';

export const FEE_GRANT_ALLOWANCE_URL = `${URL}/me/fee-grant`;
export const STATS_URL = `${DATA_LAYER}/stats`;
export const STATS_MARKET_URL = `${DATA_LAYER}/stats`;
export const urlFaucetValidation = (address) => `${DATA_LAYER}/claim-status/${address}`;
export const urlFetchAccount = (address) => `${REST_URL}/cosmos/auth/v1beta1/accounts/${address}`;
export const urlFetchIBCAccount = (REST_URL, address) => `${REST_URL}/cosmos/auth/v1beta1/accounts/${address}`;
export const urlFetchSimulate = (REST_URL) => `${REST_URL}/cosmos/tx/v1beta1/simulate`;
export const urlMyAccountStats = (address) => `${DATA_LAYER}/stats?address=${address}`;
export const MARKETPLACE_PARAMS_URL = `${config.REST_URL}/omniflix/marketplace/v1beta1/params`;

// Recap
export const RECAP_VIDEO_GENERATE_URL = `${URL}/me/recap-video`;
export const urlFetchRecapVideo = (address) => `${RECAP_URL}/api/nft-recap-video-requests/${address}`;
export const urlRoundupStats = (address) => `${RECAP_URL}/api/stats-by-address/${address}`;
export const urlFetchMintNFTDetails = () => `${NFT_CLAIM}/projects`;
export const urlFetchMintRequest = (address, projectID) => `${NFT_CLAIM}/${address}/projects/${projectID}/mint-requests`;
export const urlFetchMintQueue = (address, projectID) => `${NFT_CLAIM}/${address}/projects/${projectID}/mint-queue`;
export const urlMintNFT = (projectID) => `${NFT_CLAIM}/projects/${projectID}/mint-requests`;
export const urlFetchProjectsList = (address) => {
    const params = [];
    if (address) {
        params.push(`address=${address}`);
    }

    return `${NFT_CLAIM}/projects?${params.join('&')}`;
};
export const urlCheckNFTClaim = (denom, address) => {
    const params = ['statuses[]=BURNED', 'statuses[]=CREATED'];
    if (denom) {
        params.push(`denomId=${denom}`);
    }
    if (address) {
        params.push(`owner=${address}`);
    }

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const urlListings = (skip, limit, priceDenom, minPrice, maxPrice, sortBy, order, search) => {
    const params = ['statuses[]=LISTED', 'ipInfringement=false'];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }

    return `${DATA_LAYER}/listings?${params.join('&')}`;
};

export const urlAuctionListings = (skip, limit, priceDenom, minPrice, maxPrice, auctionType, address, sortBy, order, search) => {
    const params = ['ipInfringement=false'];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
        if (sortBy === 'end_time' && order === 'asc') {
            params.push('statuses[]=CREATED');
            params.push(`startTo=${new Date().toUTCString()}`);
        }
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }
    if (auctionType === 'myBids') {
        params.push(`participantAddress=${address}`);
    }
    if (auctionType === 'myAuctions') {
        params.push(`owner=${address}`);
    }

    return `${DATA_LAYER}/auctions?${params.join('&')}`;
};

export const urlCollectionAuction = (id, skip, limit, priceDenom, minPrice, maxPrice, auctionType, address, sortBy, order, search) => {
    const params = ['denomId=' + id, 'ipInfringement=false'];

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }
    if (auctionType === 'myBids') {
        params.push(`participantAddress=${address}`);
    }
    if (auctionType === 'myAuctions') {
        params.push(`owner=${address}`);
    }

    return `${DATA_LAYER}/auctions?${params.join('&')}`;
};

export const urlCollections = (address, skip, limit, searchKey) => {
    const params = ['sortBy=created_at', 'order=desc', 'ipInfringement=false'];
    if (address) {
        params.push(`owner=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }

    return `${DATA_LAYER}/collections?${params.join('&')}`;
};
export const urlUnVerifiedCollections = (address, skip, limit, searchKey) => {
    const params = ['sortBy=created_at', 'order=desc'];
    if (address) {
        params.push(`owner=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }

    return `${DATA_LAYER}/collections?${params.join('&')}`;
};
export const urlLaunchpadCollections = (address, skip, limit, searchKey) => {
    const params = ['sortBy=created_at', 'order=desc', 'ipInfringement=false', 'onLaunchpad=true'];
    if (address) {
        params.push(`owner=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }

    return `${DATA_LAYER}/collections?${params.join('&')}`;
};
export const urlCollectionsTable = (address, skip, limit, searchKey, sortBy, order) => {
    const params = ['ipInfringement=false'];
    if (address) {
        params.push(`owner=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${DATA_LAYER}/collections?${params.join('&')}`;
};

export const urlCollection = (id) => {
    return `${DATA_LAYER}/collections/${id}`;
};

export const urlListedCollectionNfts = (id, skip, limit, priceDenom, minPrice, maxPrice, sortBy, order, search) => {
    const params = ['denomId=' + id, 'ipInfringement=false'];

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }

    return `${DATA_LAYER}/listings?${params.join('&')}`;
};

export const urlNonListedCollectionNfts = (id, skip, limit, search) => {
    const params = ['denomId=' + id, 'listed=false', 'transferable=true', 'sortBy=created_at', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const urlNonTransferableCollectionNfts = (id, skip, limit, search) => {
    const params = ['denomId=' + id, 'transferable=false', 'sortBy=created_at', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const urlCollectionMyNfts = (id, owner, skip, limit, search) => {
    const params = ['denomId=' + id, 'owner=' + owner, 'sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }

    return `${DATA_LAYER}/nfts?${params.join('&')}`;
};

export const urlNFT = (collectionID, nftID) => `${DATA_LAYER}/collections/${collectionID}/nfts/${nftID}`;
export const urlCollectionNFT = (nftID) => `${DATA_LAYER}/nfts/${nftID}`;
export const urlNFTActivity = (nftID, denomID, skip, limit) => {
    const params = ['sortBy=created_at', 'order=desc'];
    if (nftID) {
        params.push(`nftId=${nftID}`);
    }
    if (denomID) {
        params.push(`denomId=${denomID}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${ACTIVITY_URL}/activity?${params.join('&')}`;
};
export const urlAuctionBidHistory = (auctionId, skip, limit) => {
    const params = ['statuses[]=PLACED', 'statuses[]=ACCEPTED', 'statuses[]=CLOSED'];
    if (auctionId) {
        params.push(`auctionId=${auctionId}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/bids?${params.join('&')}`;
};

export const urlUserCollections = (creator, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/collections?creator=${creator}&${params.join('&')}`;
};

export const urlOwnerCollections = (creator) => `${DATA_LAYER}/collections?creator=${creator}&sortBy=updated_at&limit=6`;

export const urlUserNFTs = (owner, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/nfts?owner=${owner}&${params.join('&')}`;
};
export const urlOwnerNFTs = (owner) => `${DATA_LAYER}/nfts?owner=${owner}&sortBy=updated_at&limit=6`;
export const urlUserListed = (owner, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/nfts?owner=${owner}&listed=true&${params.join('&')}`;
};
export const urlUserNotListed = (owner, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/nfts?owner=${owner}&listed=false&transferable=true&${params.join('&')}`;
};
export const urlUserNonTransferable = (owner, skip, limit) => {
    const params = ['sortBy=updated_at', 'order=desc', 'ipInfringement=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/nfts?owner=${owner}&transferable=false&${params.join('&')}`;
};

// User Activity
export const urlUserActivity = (address, skip, limit, sortBy, order) => {
    const params = [];
    if (address) {
        params.push(`address=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    return `${ACTIVITY_URL}/activity?${params.join('&')}`;
};
export const urlCollectionActivity = (id, skip, limit, sortBy, order) => {
    const params = [];
    if (id) {
        params.push(`denomId=${id}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${ACTIVITY_URL}/activity?${params.join('&')}`;
};

export const urlAccountTokensTraded = (id) => `${DATA_LAYER}/trade-info?address=${id}`;

export const IBC_TOKENS_LIST_URL = `${DATA_LAYER}/tokens`;
export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;

export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const urlBuyNFT = (id) => `${REST_URL}/marketplace/listings/${id}/buy-nft`;

// Home Page
export const urlFeaturedCollections = () => {
    const params = ['limit=4'];
    return `${DATA_LAYER}/collections?${params.join('&')}`;
};
export const urlRecentAuction = () => {
    const params = ['limit=10', 'sortBy=created_at', 'order=desc'];
    return `${DATA_LAYER}/auctions?${params.join('&')}`;
};
export const urlBidsAuction = () => {
    const params = ['limit=10', 'sortBy=created_at', 'order=desc', 'withBids=true'];
    return `${DATA_LAYER}/auctions?${params.join('&')}`;
};
export const urlUpcomingAuction = () => {
    const params = ['limit=10', 'statuses[]=CREATED', `startFrom=${new Date().toUTCString()}`, 'order=asc'];
    return `${DATA_LAYER}/auctions?${params.join('&')}`;
};
export const urlTopListings = () => {
    const params = ['statuses[]=LISTED', 'sortBy=created_at', 'order=desc', 'limit=10'];
    return `${DATA_LAYER}/listings?${params.join('&')}`;
};
export const urlTopCampaigns = () => {
    const params = ['active=true', 'limit=8'];
    return `${DATA_LAYER}/campaigns?${params.join('&')}`;
};
export const urlLiveLaunchpadCollections = () => {
    const params = ['limit=16', 'sortBy=start_time', 'order=desc', 'soldOut=false'];
    return `${LAUNCH_PAD}/projects?${params.join('&')}`;
};

// Campaigns
export const urlCampaigns = (skip, limit) => {
    const params = ['active=true'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/campaigns?${params.join('&')}`;
};
export const urlCampaign = (ID) => `${DATA_LAYER}/campaigns/${ID}`;

export const urlCampaignListings = (ID, skip, limit, priceDenom, minPrice, maxPrice, sortBy, order, search, random) => {
    const params = ['statuses[]=LISTED'];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }
    if (random) {
        params.push('randomize=true');
    }

    return `${DATA_LAYER}/campaigns/${ID}/listings?${params.join('&')}`;
};

export const urlCampaignAuctions = (ID, skip, limit, priceDenom, minPrice, maxPrice, auctionType, address, sortBy, order, search, random) => {
    const params = [];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
        if (sortBy === 'end_time' && order === 'asc') {
            params.push('statuses[]=CREATED');
            params.push(`startTo=${new Date().toUTCString()}`);
        }
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (priceDenom && priceDenom.length > 0) {
        priceDenom && priceDenom.length > 0 && priceDenom.map((val) => {
            return params.push('priceDenoms[]=' + val);
        });
    }
    if (minPrice) {
        params.push(`priceFrom=${minPrice}`);
    }
    if (maxPrice) {
        params.push(`priceTo=${maxPrice}`);
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }
    if (auctionType === 'myBids') {
        params.push(`participantAddress=${address}`);
    }
    if (auctionType === 'myAuctions') {
        params.push(`owner=${address}`);
    }
    if (random) {
        params.push('randomize=true');
    }

    return `${DATA_LAYER}/campaigns/${ID}/auctions?${params.join('&')}`;
};

// ITC Listings
export const urlITCStats = (address) => {
    if (address) {
        return `${DATA_LAYER}/stats/itc-campaigns?address=${address}`;
    }

    return `${DATA_LAYER}/stats/itc-campaigns`;
};
export const urlITCCampaignNFTs = (address, campaignId) => {
    const params = ['all=true'];
    // if (skip) {
    //     params.push(`skip=${skip}`);
    // }
    // if (limit) {
    //     params.push(`limit=${limit}`);
    // }

    return `${DATA_LAYER}/itc-campaigns/${campaignId}/claims/${address}/eligible-nfts?${params.join('&')}`;
};
export const urlITCCampaignClaimedNFTs = (skip, limit, address, denomId) => {
    const params = ['ipInfringement=false'];
    if (denomId) {
        params.push(`denomId=${denomId}`);
    }
    if (address) {
        params.push(`owner=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/listings?${params.join('&')}`;
};
export const urlITCCampaign = (id) => {
    return `${DATA_LAYER}/itc-campaigns/${id}`;
};
export const urlITCCampaignStats = (id, address, skip, limit, sortBy, order) => {
    const params = [];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }
    if (address) {
        params.push(`claimer=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns/${id}/claims?${params.join('&')}`;
};

export const urlITCListings = (skip, limit, sortBy, order) => {
    const params = ['verified=true'];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=status');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('statusSortOrder=asc');
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

export const urlITCMyCampaigns = (address, skip, limit, sortBy, order) => {
    const params = [];
    if (address) {
        params.push(`creator=${address}`);
    }
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=status');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('statusSortOrder=asc');
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

export const urlITCEligibleCampaigns = (address, skip, limit, sortBy, order) => {
    const params = [];
    if (address) {
        params.push(`eligibleAddress=${address}`);
    }
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=status');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('statusSortOrder=asc');
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

export const urlITCAllCampaigns = (skip, limit, sortBy, order) => {
    const params = [];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=status');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('statusSortOrder=asc');
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

// Flix drops
export const urlFLIXDrops = (skip, limit, sortBy, order) => {
    const params = ['verified=true', 'flixdrop=true'];
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=status');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('statusSortOrder=asc');
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

export const urlClaimAllNFTs = (address) => {
    const params = ['verified=true', 'flixdrop=true'];

    return `${DATA_LAYER}/itc-campaigns/claims/${address}/claimable-flix?${params.join('&')}`;
};

export const streamPayClaimableTokensUrl = (address) => {
    const params = ['types[]=STREAM_PAYMENT_TYPE_DELAYED', 'types[]=STREAM_PAYMENT_TYPE_CONTINUOUS', 'statuses[]=STREAM_PAYMENT_STATUS_ACTIVE', 'all=true'];

    if (address) {
        params.push(`address=${address}`);
        params.push(`recipient=${address}`);
    }
    return `${DATA_LAYER}/streams?${params.join('&')}`;
};

export const flixFridayBannerUrl = (type, statuses) => {
    const params = [];
    if (type) {
        params.push(`type=${type}`);
    }
    if (statuses) {
        params.push(`statuses=${statuses}`);
    }

    return `${DATA_LAYER}/banners?${params.join('&')}`;
};
