import { AppBar, Tab } from '@mui/material';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { hideSideBar } from '../../actions/navbar';
import { fetchTopCampaigns } from '../../actions/home';
// import { ReactComponent as ColoredStar } from '../../assets/recap/star_colored.svg';
// import { ReactComponent as WhiteStar } from '../../assets/recap/star_white.svg';

class NavTabs extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: '',
        };
    }

    componentDidMount () {
        if (this.props.campaigns && !this.props.campaigns.length && !this.props.campaignsInProgress) {
            this.props.fetchTopCampaigns();
        }
        const route = this.props.router.location && this.props.router.location.pathname &&
            this.props.router.location.pathname.split('/') &&
            this.props.router.location.pathname.split('/')[1];

        if (route === '') {
            this.props.router.navigate('/home');
        }

        if (route === 'marketplace') {
            this.setState({
                value: 'collectNow',
            });
        } else if (route === 'collections') {
            this.setState({
                value: 'curated',
            });
        } else {
            this.setState({
                value: route,
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if ((pp.router.location && pp.router.location.pathname) !== (this.props.router.location && this.props.router.location.pathname)) {
            const value = this.props.router.location.pathname.split('/')[1];

            if (value === 'marketplace') {
                this.setState({
                    value: 'collectNow',
                });
            } else if (value === 'collections') {
                this.setState({
                    value: 'curated',
                });
            } else {
                this.setState({
                    value: value,
                });
            }
        }
    }

    handleChange (newValue, event) {
        event.preventDefault();

        if (this.props.show) {
            this.props.hideSideBar();
        }

        if (newValue === 'collectNow') {
            this.props.router.navigate('/marketplace/collectNow');
        } else if (newValue === 'curated') {
            this.props.router.navigate('/collections/' + newValue);
        } else if (newValue === 'campaigns') {
            this.props.router.navigate('/campaigns/curated');
        } else {
            this.props.router.navigate('/' + newValue);
        }
        this.setState({
            value: newValue,
        });
    }

    render () {
        const a11yProps = (index) => {
            return {
                id: `nav-tab-${index}`,
            };
        };

        return (
            <AppBar className="horizontal_tabs" position="static">
                <div className="tabs_content">
                    <Tab
                        disableRipple
                        className={'tab ' + (this.state.value === 'home' ? 'active_tab' : '')}
                        component="a"
                        href="/home"
                        label={<p className="text">{variables[this.props.lang].home}</p>}
                        role="button"
                        value="home"
                        onClick={(event) => this.handleChange('home', event)}
                        {...a11yProps(0)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.state.value === 'collectNow' ? 'active_tab' : '')}
                        component="a"
                        href="/marketplace/collectNow"
                        label={<p className="text">{variables[this.props.lang]['market_place']}</p>}
                        role="button"
                        value="collectNow"
                        onClick={(event) => this.handleChange('collectNow', event)}
                        {...a11yProps(1)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.state.value === 'curated' ? 'active_tab' : '')}
                        component="a"
                        href="/collections/curated"
                        label={<p className="text">{variables[this.props.lang].collections}</p>}
                        role="button"
                        value="curated"
                        onClick={(event) => this.handleChange('curated', event)}
                        {...a11yProps(2)} />
                    {/* <Tab */}
                    {/*     disableRipple */}
                    {/*     className={'tab ' + (this.state.value === 'roundup' ? 'active_tab' : '')} */}
                    {/*     component="a" */}
                    {/*     href="/roundup" */}
                    {/*     label={ */}
                    {/*         <p className="text recap_text"> */}
                    {/*             {variables[this.props.lang].recap} */}
                    {/*             {this.state.value === 'roundup' */}
                    {/*                 ? <ColoredStar/> */}
                    {/*                 : <WhiteStar/>} */}
                    {/*         </p>} */}
                    {/*     role="button" */}
                    {/*     value="roundup" */}
                    {/*     onClick={(event) => this.handleChange('roundup', event)} */}
                    {/*     {...a11yProps(2)} /> */}
                    {/*    disableRipple */}
                    {/*    className={'tab ' + (this.state.value === 'roundup' ? 'active_tab' : '')} */}
                    {/*    component="a" */}
                    {/*    href="/roundup" */}
                    {/*    label={ */}
                    {/*        <p className="text recap_text"> */}
                    {/*            {variables[this.props.lang].recap} */}
                    {/*            {this.state.value === 'roundup' */}
                    {/*                ? <ColoredStar/> */}
                    {/*                : <WhiteStar/>} */}
                    {/*        </p>} */}
                    {/*    role="button" */}
                    {/*    value="roundup" */}
                    {/*    onClick={(event) => this.handleChange('roundup', event)} */}
                    {/*    {...a11yProps(2)} /> */}
                    {this.props.campaigns && this.props.campaigns.length
                        ? <Tab
                            disableRipple
                            className={'tab ' + (this.state.value === 'curations' ? 'active_tab' : '')}
                            component="a"
                            href="/curations"
                            label={<p className="text">{variables[this.props.lang].curations}</p>}
                            role="button"
                            value="curations"
                            onClick={(event) => this.handleChange('curations', event)}
                            {...a11yProps(3)} /> : null}
                    <Tab
                        disableRipple
                        className={'tab ' + (this.state.value === 'campaigns' ? 'active_tab' : '')}
                        component="a"
                        href="/campaigns/curated"
                        label={<p className="text">{variables[this.props.lang].campaigns}</p>}
                        role="button"
                        value="campaigns"
                        onClick={(event) => this.handleChange('campaigns', event)}
                        {...a11yProps(4)} />
                </div>
            </AppBar>
        );
    }
}

NavTabs.propTypes = {
    campaigns: PropTypes.array.isRequired,
    campaignsInProgress: PropTypes.bool.isRequired,
    fetchTopCampaigns: PropTypes.func.isRequired,
    hideSideBar: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            campaignName: PropTypes.string,
            campaignID: PropTypes.string,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        campaigns: state.home.campaigns.result,
        campaignsInProgress: state.home.campaigns.inProgress,
        lang: state.language,
        show: state.navbar.show,
    };
};

const actionToProps = {
    fetchTopCampaigns,
    hideSideBar,
};

export default connect(stateToProps, actionToProps)(NavTabs);
