import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './containers/NavBar';
import RouterSkeletonLoader from './components/RouterSkeletonLoader';
import SingleCollectionOld from './components/DeprecatedRoutes/SingleCollection';
import SingleCollectionActivityOld from './components/DeprecatedRoutes/SingleCollectionActivity';
// import Recap from './containers/Recap';

const HomePage = lazy(() => import('./containers/HomePage').then().catch());
const MarketPlace = lazy(() => import('./containers/MarketPlace').then().catch());
const MyAccount = lazy(() => import('./containers/MyAccount').then().catch());
const SingleCollection = lazy(() => import('./containers/SingleCollection').then().catch());
const SingleCollectionActivity = lazy(() => import('./containers/SingleCollection/ActivityPage').then().catch());
const SingleAsset = lazy(() => import('./containers/SingleAsset').then().catch());
const Collections = lazy(() => import('./containers/Collections').then().catch());
const SingleCampaign = lazy(() => import('./containers/SingleCampaign').then().catch());

const InteractiveClaim = lazy(() => import('./containers/ITCCampaigns').then().catch());
const ITCCampaign = lazy(() => import('./containers/ITCCampaigns/Campaign').then().catch());

const routes = [{
    path: '/home',
    component: HomePage,
}, {
    path: '/marketplace/collectNow',
    component: MarketPlace,
}, {
    path: '/marketplace/auctions',
    component: MarketPlace,
}, {
    path: '/collections',
    component: Collections,
}, {
    path: '/collections/curated',
    component: Collections,
}, {
    path: '/collections/launchpad',
    component: Collections,
}, {
    path: '/collections/all',
    component: Collections,
}, {
    path: '/curations',
    component: SingleCampaign,
}, {
    path: '/curations/:campaignName-:campaignID',
    component: SingleCampaign,
}, {
    path: '/account/:address/:tab',
    component: MyAccount,
}, {
    path: '/account/:address',
    component: MyAccount,
}, {
    path: '/c/:id',
    component: SingleCollection,
}, {
    path: '/c/:id/activity',
    component: SingleCollectionActivity,
}, {
    path: '/collection/:id',
    component: SingleCollectionOld,
}, {
    path: '/collection/:id/activity',
    component: SingleCollectionActivityOld,
}, {
    path: '/nft/:nftID',
    component: SingleAsset,
}, {
    path: '/c/:collectionID/:nftID',
    component: SingleAsset,
}, {
    path: '/campaigns',
    component: InteractiveClaim,
}, {
    path: '/campaigns/curated',
    component: InteractiveClaim,
}, {
    path: '/campaigns/eligible',
    component: InteractiveClaim,
}, {
    path: '/campaigns/created',
    component: InteractiveClaim,
}, {
    path: '/campaigns/all',
    component: InteractiveClaim,
// }, {
//     path: '/campaigns/FlixDrop',
//     component: InteractiveClaim,
}, {
    path: '/campaign/:id',
    component: ITCCampaign,
// }, {
//     path: '/roundup',
//     component: Recap,
}];

const Router = () => {
    return (
        <div className="main_content">
            <Suspense fallback={<RouterSkeletonLoader/>}>
                <>
                    <NavBar/>
                    <div className="content_div">
                        <Routes>
                            {routes.map((route) =>
                                <Route
                                    key={route.path}
                                    exact
                                    element={<route.component/>}
                                    path={route.path}/>,
                            )}
                            <Route
                                exact
                                element={<HomePage/>}
                                path="*"/>
                        </Routes>
                    </div>
                </>
            </Suspense>
        </div>
    );
};

export default Router;
