import { combineReducers } from 'redux';
import {
    CONFIRM_MINT_NFT_DIALOG_HIDE,
    CONFIRM_MINT_NFT_DIALOG_SHOW,
    FETCH_NFT_DETAILS_ERROR,
    FETCH_NFT_DETAILS_IN_PROGRESS,
    FETCH_NFT_DETAILS_SUCCESS,
    GENERATE_VIDEO_IN_PROGRESS_SET,
    GENERATE_VIDEO_STATUS_SET,
    MINT_NFT_ERROR,
    MINT_NFT_IN_PROGRESS,
    MINT_NFT_IN_PROGRESS_SET,
    MINT_NFT_SUCCESS,
    MINT_QUEUE_FETCH_ERROR,
    MINT_QUEUE_FETCH_IN_PROGRESS,
    MINT_QUEUE_FETCH_SUCCESS,
    MINT_REQUEST_FETCH_ERROR,
    MINT_REQUEST_FETCH_IN_PROGRESS,
    MINT_REQUEST_FETCH_SUCCESS,
    NFT_CLAIM_STATUS_CHECK_ERROR,
    NFT_CLAIM_STATUS_CHECK_IN_PROGRESS,
    NFT_CLAIM_STATUS_CHECK_SUCCESS,
    PROJECTS_LIST_FETCH_ERROR,
    PROJECTS_LIST_FETCH_IN_PROGRESS,
    PROJECTS_LIST_FETCH_SUCCESS,
    RECAP_VIDEO_FETCH_ERROR,
    RECAP_VIDEO_FETCH_IN_PROGRESS,
    RECAP_VIDEO_FETCH_SUCCESS,
    RECAP_VIDEO_GENERATE_ERROR,
    RECAP_VIDEO_GENERATE_IN_PROGRESS,
    RECAP_VIDEO_GENERATE_SUCCESS,
    ROUNDUP_STATS_FETCH_ERROR,
    ROUNDUP_STATS_FETCH_IN_PROGRESS,
    ROUNDUP_STATS_FETCH_SUCCESS,
} from '../constants/recap';
import { DISCONNECT_SET } from '../constants/account';
import { TX_SIGN_ERROR } from '../constants/wallet';

const confirmMintNFTDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CONFIRM_MINT_NFT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CONFIRM_MINT_NFT_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const generateVideo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case RECAP_VIDEO_GENERATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case RECAP_VIDEO_GENERATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case RECAP_VIDEO_GENERATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const recapVideo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case RECAP_VIDEO_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case RECAP_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case RECAP_VIDEO_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            value: {},
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const roundupStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ROUNDUP_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ROUNDUP_STATS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case ROUNDUP_STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            value: {},
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const mintNftDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_NFT_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NFT_DETAILS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case FETCH_NFT_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
            value: {},
        };
    default:
        return state;
    }
};

const nftMint = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_NFT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MINT_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CONFIRM_MINT_NFT_DIALOG_HIDE:
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const mintRequests = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_REQUEST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_REQUEST_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.projectID] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case MINT_REQUEST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const mintQueue = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_QUEUE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_QUEUE_FETCH_SUCCESS: {
        const value = { ...state.value };
        value[action.projectID] = action.value;

        return {
            ...state,
            inProgress: false,
            value: value,
        };
    }
    case MINT_QUEUE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const projectsList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case PROJECTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROJECTS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROJECTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const generateInProgress = (state = {
    inProgress: false,
    mintNFTInProgress: false,
    status: '',
    number: null,
}, action) => {
    switch (action.type) {
    case GENERATE_VIDEO_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    case MINT_NFT_IN_PROGRESS_SET:
        return {
            ...state,
            mintNFTInProgress: action.value,
        };
    case TX_SIGN_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case GENERATE_VIDEO_STATUS_SET:
        return {
            ...state,
            status: action.value,
            number: action.number,
        };
    default:
        return state;
    }
};

const claimStatus = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case NFT_CLAIM_STATUS_CHECK_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_CLAIM_STATUS_CHECK_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case NFT_CLAIM_STATUS_CHECK_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    confirmMintNFTDialog,
    generateVideo,
    recapVideo,
    roundupStats,
    mintNftDetails,
    nftMint,
    mintRequests,
    mintQueue,
    projectsList,
    generateInProgress,
    claimStatus,
});
